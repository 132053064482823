<template>
  <v-container fluid>
    <v-progress-circular
        indeterminate
        color="green"
        v-if="initialLoader"
        style="margin-left: 10px;"
    ></v-progress-circular>
    <h1>SuperAdmin</h1>
    <p>You really should not be here.</p>
    <v-row v-if="!initialLoader">
      <v-col cols=2>
        <span class="d-flex flex-row">
          <h2>Document Types</h2>
        </span>
        <v-text-field label="name" :disabled="editMode" v-model="documentTypeForm.name" />
        <v-text-field label="width" v-model="documentTypeForm.metadata.width" />
        <v-text-field label="height" v-model="documentTypeForm.metadata.height" />
        <v-text-field label="border" v-model="documentTypeForm.metadata.border" />
        <v-text-field label="bcTerm" v-model="documentTypeForm.metadata.bcTerm" />
        <v-btn v-if="editMode" @click="updateDocumentType" color="info" class="mr-2">Update Document Type</v-btn>
        <v-btn v-if="editMode" @click="deleteDocumentType" color="error">Delete Document Type</v-btn>
        <v-btn v-else :disabled="!documentTypeForm.name" @click="createDocumentType" color="success">Create Document Type</v-btn>
        <v-data-table
          v-if="!initialLoader"
          :headers="documentTypes.tableHeaders"
          :items="documentTypes.data"
          :items-per-page="-1"
          @click:row="selectDocumentTypeRow"
        />
      </v-col>
      <v-col cols=5>
        <h2>PUG Template</h2>
        <v-textarea
          label="Pug"
          outlined
          v-model="newTemp.pugPayload"
          height=600px
        ></v-textarea>
      </v-col>
      <v-col cols=5>
        <h2>CSS Template</h2>
        <v-textarea
          label="CSS"
          outlined
          v-model="newTemp.cssPayload"
          height=600px
        ></v-textarea>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios';

export default {
  name: 'Home',
  data () {
    return {
      initialLoader: true,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      editMode: false,
      newTemp: {
        pugPayload: '',
        cssPayload: ''
      },
      selectedId: '',
      documentTypes: {
        tableHeaders:[
          {text: 'ID', align: 'start', value: 'id'},
          { text: 'Name', value: 'name'},
          { text: 'Data', value: 'metadata'},
        ],
        data: null,
      },
      documentTypeForm: {
        name: "",
        metadata: {
          height: '',
          width: '',
          border: '',
          bcTerm: '',
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint'])
  },
  async mounted(){
    try { 
      let res = await axios.get(`${this.getEndpoint}/api/documentTypes/`)
      if(res.data.error) throw res.data.error

      this.documentTypes.data = res.data.data
      // this.documentTypes.data.forEach(x=>x.metadata = JSON.stringify(x.metadata))
      this.initialLoader = false
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async selectDocumentTypeRow(row){
      try {
        this.selectedId = row.id
        this.documentTypeForm = row
        this.editMode = true
        let res = await axios.get(`${this.getEndpoint}/api/documentTypes/${this.selectedId}`)
        if(res.data.error) throw res.data.error
        this.newTemp.cssPayload = res.data.data.templates.css
        this.newTemp.pugPayload = res.data.data.templates.pug
      } catch (error) {
       console.log(error) 
      }
      
    },
    async updateDocumentType(){
      try {
        let templateData = {}
        if(this.newTemp.cssPayload) templateData.cssPayload = this.newTemp.cssPayload
        if(this.newTemp.pugPayload) templateData.pugPayload = this.newTemp.pugPayload
        if(templateData != {}) templateData['name'] = this.documentTypeForm.name
        let obj = {payload: templateData, configs: this.documentTypeForm}
        let res = await axios.put(`${this.getEndpoint}/api/documentTypes/${this.selectedId}`, obj)
        if(res.data.error) throw res.data.error

        this.documentTypeForm = {
          name: "",
          metadata: {
            height: '',
            width: '',
            border: '',
            bcTerm: '',
          }
        }
        this.selectedId = ''
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    async deleteDocumentType(){
      try {
        let res = await axios.delete(`${this.getEndpoint}/api/documentTypes/${this.selectedId}`)
        if(res.data.error) throw res.data.error

        this.documentTypeForm = {
          name: "",
          metadata: {
            height: '',
            width: '',
            border: '',
            bcTerm: '',
          }
        }
        this.selectedId = ''
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    async createDocumentType(){
      try {
        if(this.editMode) throw "Cannot create in editmode."
        if(this.documentTypeForm.name && this.newTemp.pugPayload){
          this.newTemp['name'] = this.documentTypeForm.name
          let obj = {payload: this.newTemp, configs: this.documentTypeForm}
          let res = await axios.post(`${this.getEndpoint}/api/documentTypes/`, obj)
          if(res.data.error) throw res.data.error

          this.newTemp = {pugPayload: '', cssPayload: ''}
          this.documentTypeForm = {
            name: "",
            metadata: {
              height: '',
              width: '',
              border: '',
              bcTerm: '',
            }
          }
          this.selectedId = ''
        }
      }catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
